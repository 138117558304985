<mat-tab-group mat-align-tabs="start" animationDuration="0ms" *transloco="let t" [disablePagination]="true">
  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="contract-information ui-test--contract-information-mat-icon-info">info</mat-icon>
      {{ t('Common_ContractInformation') }}
    </ng-template>
    <div class="flex-col">
      <contract-information [contract]="contract" [dashboardData]="dashboardData"></contract-information>

      <div
        *ngIf="dashboardData.canCreateRequest && !dashboardData.isReadOnly && contract.productTypes.length > 0"
        class="flex flex-1 justify-center mt-20">
        <button
          class="ui-test--contract-details-button-create-request"
          mat-raised-button
          color="primary"
          [matMenuTriggerFor]="createRequestMenu"
          id="DashboardLinkServiceRequest">
          <mat-icon class="ui-test--contract-details-mat-icon-create-request">add_shopping_cart</mat-icon>
          {{ t('Dashboard_Index_CreateRequest') }}
        </button>
        <mat-menu #createRequestMenu="matMenu">
          <ng-container *ngFor="let productType of contract.productTypes; let i = index">
            <button
              [class]="'ui-test--contract-details-button-request-type-' + i"
              mat-menu-item
              (click)="startRequestForProductType(productType)">
              <mat-icon [class]="'ui-test--contract-details-mat-icon-request-type-' + i">
                {{ productType.displayText | productTypeIcon }}
              </mat-icon>
              {{ dashboardService.getProductTypeText(productType.displayText) }}
            </button>
          </ng-container>
        </mat-menu>
      </div>
    </div>
  </mat-tab>
  <mat-tab
    class="ui-test--contract-details-mat-tab-warrantor"
    *ngIf="contract.warrantorDealer?.sortedDetailDisplayProperties?.length">
    <ng-template mat-tab-label>
      <mat-icon class="ui-test--contract-details-mat-icon-warrantor">person</mat-icon>
      {{ t('Common_WarrantorDealerInformation') }}
    </ng-template>
    <warrantor-dealer [warrantorDealer]="contract.warrantorDealer"></warrantor-dealer>
  </mat-tab>
  <mat-tab
    class="ui-test--contract-details-mat-tab-customer-information"
    *ngIf="contract.customer?.sortedDetailDisplayProperties?.length">
    <ng-template mat-tab-label>
      <mat-icon class="customer-information ui-test--contract-details-mat-icon-customer-information">person</mat-icon>
      {{ t('Common_CustomerInformation') }}
    </ng-template>
    <customer-information [customer]="contract.customer"></customer-information>
  </mat-tab>
  <mat-tab class="ui-test--contract-details-mat-tab-vehicle-information">
    <ng-template mat-tab-label>
      <mat-icon class="vehicle-information ui-test--contract-details-mat-icon-vehicle-information">drive_eta</mat-icon>
      {{ t('Common_VehicleInformation') }}
    </ng-template>
    <vehicle-information [vehicle]="contract.vehicle"></vehicle-information>
  </mat-tab>
</mat-tab-group>

<print [contract]="contract" [dashboardData]="dashboardData"></print>
