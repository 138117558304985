<ng-container *transloco="let t">
  <h2 mat-dialog-title class="ui-test--offer-criteria-choose-header-search-criteria">
    {{ t('OfferPanelComponents_EnterAdditionalParameters_Headline') }}
  </h2>
  <mat-dialog-content>
    <p class="ui-test--offer-criteria-choose-paragraph-hint">
      {{ t('OfferPanelComponents_EnterAdditionalParameters_Text') }}
    </p>
    <form [formGroup]="offerCriteriaSearchForm" *ngFor="let missingParameter of missingParameters; let i = index">
      <!-- Parameter type == String -->
      <mat-form-field class="row flex-grow-50 w-full" *ngIf="missingParameter.parameterType === parameterTypes.string">
        <mat-label [class]="'ui-test--offer-criteria-choose-mat-label-input-' + i">
          {{ missingParameter.displayName ? missingParameter.displayName : t('OfferPanelComponent_LabelFor_' +
          missingParameter.key) }}
        </mat-label>
        <input
          [class]="'ui-test--offer-criteria-choose-input-input-' + i"
          matInput
          (blur)="onBlur(missingParameter.key)"
          [formControlName]="missingParameter.key" />
        <mat-error [class]="'ui-test--offer-criteria-choose-mat-error-input' + i">
          {{t('OfferPanelComponent_LabelFor_ExtendedSearchParameterInvalid') }}
        </mat-error>
      </mat-form-field>

      <!-- Parameter type == Boolean -->
      <div class="row" *ngIf="missingParameter.parameterType === parameterTypes.boolean">
        <mat-label [class]="'ui-test--offer-criteria-choose-mat-label-radio-button-' + i">
          {{ missingParameter.displayName ? missingParameter.displayName : t('OfferPanelComponent_LabelFor_' +
          missingParameter.key) }}
        </mat-label>
        <mat-radio-group aria-label="Select an option" [formControlName]="missingParameter.key">
          <mat-radio-button
            class="mx-10 my-10"
            [value]="true"
            color="primary"
            [class]="'ui-test--offer-criteria-choose-mat-radio-button-yes-' + i">
            {{ t('Common_Yes') }}
          </mat-radio-button>
          <mat-radio-button
            class="mx-10 my-10"
            [value]="false"
            color="primary"
            [class]="'ui-test--offer-criteria-choose-mat-radio-button-no-' + i">
            {{ t('Common_No') }}
          </mat-radio-button>
        </mat-radio-group>
      </div>

      <!-- Parameter type == DateTime -->
      <mat-form-field
        class="row flex-grow-50 w-full"
        *ngIf="missingParameter.parameterType === parameterTypes.dateTime">
        <mat-label [class]="'ui-test--offer-criteria-choose-mat-label-display-name-' + i">
          {{ missingParameter.displayName ? missingParameter.displayName : t('OfferPanelComponent_LabelFor_' +
          missingParameter.key) }}
        </mat-label>
        <input
          matInput
          [matDatepicker]="dynamicDatePicker"
          [max]="missingParameter.key !== 'ContractEnd' ? today : ''"
          maxlength="10"
          [class]="'ui-test--offer-criteria-choose-input-input-datetime-' + i"
          [formControlName]="missingParameter.key"
          (click)="dynamicDatePicker.open()"
          (blur)="updateDate($event, missingParameter.key)" />
        <mat-datepicker-toggle matSuffix [for]="dynamicDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #dynamicDatePicker></mat-datepicker>
        <mat-error [class]="'ui-test--offer-criteria-choose-mat-error-extended-search-parameter-invalid-' + i">
          {{t('OfferPanelComponent_LabelFor_ExtendedSearchParameterInvalid') }}
        </mat-error>
      </mat-form-field>

      <!-- Parameter type == Enum Dropdown -->
      <mat-form-field class="row flex-grow-50 w-full" *ngIf="missingParameter.parameterType === parameterTypes.enum">
        <mat-label [class]="'ui-test--offer-criteria-choose-mat-label-display-name-dropdown-' + i">
          {{ missingParameter.displayName ? missingParameter.displayName : t('OfferPanelComponent_LabelFor_' +
          missingParameter.key) }}
        </mat-label>
        <mat-select
          [formControlName]="missingParameter.key"
          [class]="'ui-test--offer-criteria-choose-mat-select-option-' + i">
          <mat-option
            *ngFor="let brand of missingParameter.enumValues; let index = index"
            [value]="brand"
            [class]="'ui-test--offer-criteria-choose-mat-option-option-' + index + '-selection-' + i">
            {{brand}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button class="ui-test--offer-criteria-choose-button-cancel" mat-stroked-button mat-dialog-close>
      {{ t('Common_Cancel') }}
    </button>
    <button
      mat-raised-button
      class="ui-test--offer-criteria-choose-button-search"
      color="primary"
      mat-dialog-close
      [disabled]="!offerCriteriaSearchForm.valid"
      (click)="onSearch()">
      {{ t('Common_Search') }}
    </button>
  </mat-dialog-actions>
</ng-container>
